
import { Component, Prop, Vue } from 'vue-property-decorator';
import LabelReporistory from '@/repositories/LabelReporistory';
import { WorkflowService } from '@/services/WorkflowService';
import { handleModalConfirm } from '@/helpers/Workflow/WorkflowHelper';
import { StatusNode } from '@/interfaces/components/Workflow/StatusNode';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProjectLabelTemplateRepository from '@/repositories/ProjectLabelTemplateRepository';
import Locale from '@/models/Locale';

@Component({
    name: 'SelectStatusModal',
    components: {},
})
export default class SelectStatusModal extends Vue {
    @Prop({ required: true }) private isModalVisible!: boolean;
    @Prop({ required: true }) private isWorkflowTemplate!: boolean;
    @Prop({ required: true }) private target!: null | StatusNode;
    @Prop({ required: true }) private localeEntity!: null | Locale;
    @Prop({ required: true }) private workflowService!: WorkflowService;

    private selectedStatusId = '';

    private get dropdownSource() {
        return this.isWorkflowTemplate ? this.projectLabelTemplates : this.labels;
    }

    private get labels() {
        return LabelReporistory.getAll();
    }

    private get projectLabelTemplates() {
        if (this.localeEntity == null) {
            return [];
        }

        return ProjectLabelTemplateRepository.getAllByLocale(this.localeEntity.id);
    }

    private onModalConfirm() {
        try {
            handleModalConfirm({
                customStatus: null,
                selectedStatusId: this.selectedStatusId,
                workflowService: this.workflowService,
                target: this.target,
                isWorkflowTemplate: this.isWorkflowTemplate,
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            return;
        }
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state: true,
            component: 'workflow',
        });

        this.hideModal();
    }

    private hideModal() {
        this.selectedStatusId = '';
        this.$emit('update:isModalVisible', false);
        this.$emit('update:target', null);
    }

    private onModalCancel() {
        this.hideModal();
    }
}
