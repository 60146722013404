import WorkflowTemplate from '@/models/WorkflowTemplate';

export default class WorkflowTemplateRepository {
    public static getAll() {
        return WorkflowTemplate.all();
    }

    public static getById(id: string) {
        return WorkflowTemplate.query().whereId(id).withAllRecursive().first();
    }

    public static getFirst() {
        return WorkflowTemplate.query().withAllRecursive().first();
    }
}
