
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import FlowChart from 'flowchart-vue';
import { WorkflowService } from '@/services/WorkflowService';
import {
    createOrUpdateWorkflow,
    fetchLocaleSpecificWorkflowTemplate,
    fetchUserGroupsWorkflow,
    handleBackendErrors,
    renderFlowchart,
    retrieveConnections,
    retrieveNodes,
} from '@/helpers/Workflow/WorkflowHelper';
import { StatusNode } from '@/interfaces/components/Workflow/StatusNode';
import { Connection } from '@/interfaces/components/Workflow/Connection';
import SelectStatusModal from '@/components/views/Workflow/SelectStatusModal.vue';
import WorkflowActions from '@/components/views/Workflow/WorkflowActions.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import LocaleRepository from '@/repositories/LocaleRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { SystemNodeType } from '@/enums/components/Workflow/SystemNodeType';
import WorkflowRepository from '@/repositories/WorkflowRepository';
import WorkflowTemplateRepository from '@/repositories/WorkflowTemplateRepository';
import Workflow from '@/models/Workflow';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

@Component({
    name: 'WorkflowContainer',
    components: { SelectStatusModal, FlowChart, WorkflowActions },
})
export default class WorkflowContainer extends Vue {
    @Prop({ default: false }) private isReadonly!: boolean;
    @Prop({ default: null }) private locale!: string | null;
    @Prop({ default: null }) private activeLabelId!: string | null;
    @Prop({ default: false }) private isOrderStatusActive!: boolean;

    private nodes: StatusNode[] = [];
    private connections: Connection[] = [];
    private selectedNodes: StatusNode[] = [];
    private selectedConnections: Connection[] = [];
    private deletedNodesIds: string[] = [];
    private deletedConnectionsIds: string[] = [];
    private workflowService: WorkflowService | null = null;
    private target: StatusNode | null = null;
    private isSelectStatusModalVisible = false;
    private renderFlowchartNode = renderFlowchart;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get shouldActionsBeVisible() {
        if (this.isReadonly) {
            return false;
        }

        return this.workflowService != null && this.chartReference() != null;
    }

    private get localeEntity() {
        if (this.locale == null) {
            return null;
        }

        return LocaleRepository.getLocaleByCode(this.locale);
    }

    private get isWorkflowTemplate() {
        return this.locale != null;
    }

    private get shouldModalBeVisible() {
        if (this.isReadonly) {
            return false;
        }

        return this.workflowService != null;
    }

    private onKeyboardDelete(event: KeyboardEvent) {
        if (this.workflowService == null) {
            return;
        }

        this.workflowService.keyboardDelete(event, this.selectedNodes, this.selectedConnections);

        this.selectedNodes = [];
        this.selectedConnections = [];
    }

    private onSelectNode(nodes: StatusNode[]) {
        this.selectedNodes = nodes;
    }

    private onSelectConnection(connections: Connection[]) {
        this.selectedConnections = connections;
    }

    private chartReference() {
        if (!this.$refs.chart == null) {
            return null;
        }

        return this.$refs.chart as FlowChart;
    }

    private onDisconnect(connection: Connection) {
        if (typeof connection.id === 'number') {
            return;
        }
        this.deletedConnectionsIds.push(connection.id);
    }

    private async resetWorkflow() {
        const currentWorkflow =
            this.localeEntity == null ? WorkflowRepository.getFirst() : WorkflowTemplateRepository.getFirst();

        if (currentWorkflow == null) {
            return;
        }

        const deletedNodesIds = this.nodes
            .filter((node) => node.type === 'operation' && node.id !== '1')
            .map((node) => {
                return {
                    id: node.id,
                };
            });

        await Workflow.resetToDefault(currentWorkflow.id, deletedNodesIds);

        this.updateActiveAndDeletedNodesAndConnections();
    }

    private async handleChartSave(nodes: StatusNode[], connections: Connection[]) {
        this.loadingOverlay.start();
        try {
            await createOrUpdateWorkflow(
                nodes,
                connections,
                this.deletedNodesIds,
                this.deletedConnectionsIds,
                this.localeEntity
            );
        } catch (e) {
            const errorObj = e as Error | any;

            this.loadingOverlay.stop();
            handleBackendErrors(
                errorObj,
                errorObj.response && errorObj.response.data ? errorObj.response.data.errors : []
            );
            return;
        }

        this.updateActiveAndDeletedNodesAndConnections();
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state: false,
            component: 'workflow',
        });

        this.$notification.success({
            message: this.$t('Tijek rada je uspješno uređen!') as string,
            description: '',
        });
        this.loadingOverlay.stop();
    }

    private handleEditNode(node: StatusNode) {
        if (node.type === SystemNodeType.START || node.type === SystemNodeType.END) {
            return;
        }

        this.target = node;
        this.showModal();
    }

    private handleConnect(connection: Connection, connections: StatusNode[]) {
        const roltekProductionNode = connections.find(
            (availableConnection) => availableConnection.type === SystemNodeType.ROLTEK_PRODUCTION
        );

        if (roltekProductionNode == null) {
            return;
        }

        (this.workflowService as WorkflowService).removeConnectionIfDestinationIsRoltekProduction(
            connection,
            roltekProductionNode
        );
    }

    private handleDeleteEvent(node: StatusNode) {
        if (node.id.includes('new')) {
            return;
        }
        this.deletedNodesIds.push(node.id);
    }

    private showModal() {
        this.isSelectStatusModalVisible = true;
    }

    private async mounted() {
        this.workflowService = new WorkflowService(this.chartReference());

        await this.fetchWorkflow();
        this.updateActiveAndDeletedNodesAndConnections();

        window.addEventListener('keyup', this.onKeyboardDelete);
    }

    private beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyboardDelete);
    }

    private updateActiveAndDeletedNodesAndConnections() {
        this.deletedNodesIds = [];
        this.deletedConnectionsIds = [];

        this.nodes = retrieveNodes(this.isWorkflowTemplate, this.isOrderStatusActive, this.activeLabelId);
        this.connections = retrieveConnections(this.isWorkflowTemplate);
    }

    private async fetchWorkflow() {
        try {
            if (this.isWorkflowTemplate) {
                await fetchLocaleSpecificWorkflowTemplate(this.localeEntity);
            } else {
                await fetchUserGroupsWorkflow();
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });

            return Promise.reject();
        }

        return Promise.resolve();
    }

    @Watch('locale')
    private async onLocaleChange() {
        if (this.locale == null) {
            return;
        }
        this.loadingOverlay.start();

        await fetchLocaleSpecificWorkflowTemplate(this.localeEntity);
        this.updateActiveAndDeletedNodesAndConnections();

        this.loadingOverlay.stop();
    }
}
