import Workflow from '@/models/Workflow';

export default class WorkflowRepository {
    public static getAll() {
        return Workflow.all();
    }

    public static getById(id: string) {
        return Workflow.query().whereId(id).withAllRecursive().first();
    }

    public static getFirst() {
        return Workflow.query().withAllRecursive().first();
    }
}
