import LabelReporistory from '@/repositories/LabelReporistory';
import { WorkflowModalSettings } from '@/interfaces/components/Workflow/WorkflowModalSettings';
import i18n from '@/i18n';
import Label from '@/models/Label';
// @ts-ignore
import * as d3 from 'd3';
import { StatusNode } from '@/interfaces/components/Workflow/StatusNode';
import Workflow from '@/models/Workflow';
import WorkflowRepository from '@/repositories/WorkflowRepository';
import { Connection } from '@/interfaces/components/Workflow/Connection';
import { capitalizeWord } from '@/helpers/TranslationHelper';
import { WorkflowNodeType } from '@/enums/components/Workflow/WorkflowNodeType';
import { TransformedNodes } from '@/interfaces/components/Workflow/TransformedNodes';
import { TransformedConnections } from '@/interfaces/components/Workflow/TransformedConnections';
import { WorkflowRequestResource } from '@/interfaces/components/Workflow/WorkflowRequestResource';
import ProjectLabelWorkflowNode from '@/models/ProjectLabelWorkflowNode';
import { JsonAPIError } from '@/interfaces/general/JsonAPIError';
import { notification } from 'ant-design-vue';
import WorkflowTemplate from '@/models/WorkflowTemplate';
import Locale from '@/models/Locale';
import WorkflowTemplateRepository from '@/repositories/WorkflowTemplateRepository';
import ProjectLabelTemplateWorkflowNode from '@/models/ProjectLabelTemplateWorkflowNode';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';
import ProjectLabelTemplateRepository from '@/repositories/ProjectLabelTemplateRepository';
import SystemWorkflowNode from '@/models/SystemWorkflowNode';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import { SystemNodeType } from '@/enums/components/Workflow/SystemNodeType';

/**
 * Generates the suggested width for a node, based on the length of the label name
 * @param {string} label - label name which length will be used
 */
export function generateAppropriateNodeWidth(label: string) {
    const characterBuffer = 4;
    const widthMultiplier = 7;
    const defaultWidth = 120;
    const suggestedWidth = (label.length + characterBuffer) * widthMultiplier;

    return Math.max(defaultWidth, suggestedWidth);
}

/**
 * Creates initial nodes that should be present on each workflow
 * @return {array} Returns an array of StatusNode with the default nodes
 */
function generateDefaultNodes(): StatusNode[] {
    return [
        {
            id: SystemNodeType.START,
            x: 450,
            y: 20,
            type: SystemNodeType.START,
            status: null,
            nodeType: WorkflowNodeType.SYSTEM_NODE,
        },
        {
            id: SystemNodeType.END,
            x: 450,
            y: 520,
            type: SystemNodeType.END,
            status: null,
            nodeType: WorkflowNodeType.SYSTEM_NODE,
        },
        {
            id: `roltekProduction`,
            x: 820,
            y: 480,
            type: SystemNodeType.ROLTEK_PRODUCTION,
            status: null,
            nodeType: WorkflowNodeType.SYSTEM_NODE,
            width: 150,
            approvers: [{ name: i18n.t('Roltek produkcija') as string }],
        },
    ];
}

/**
 * Tries to fetch the workflow for the current user group
 * @return {Promise} Returns nothing if successful or an error if not
 */
export async function fetchUserGroupsWorkflow(): Promise<void> {
    try {
        await Workflow.getAll();
    } catch (e) {
        return Promise.reject(e);
    }
    return Promise.resolve();
}

/**
 * Tries to fetch the workflow template for the given locale
 * @param localeEntity - The locale entity for which the workflow should be fetched
 * @return {Promise} Returns nothing if successful or an error if not
 */
export async function fetchLocaleSpecificWorkflowTemplate(localeEntity: Locale | null): Promise<void> {
    if (localeEntity == null) {
        return Promise.reject(new Error('Locale was not found'));
    }

    try {
        await WorkflowTemplate.getByLocale(localeEntity.id);
    } catch (e) {
        return Promise.reject(e);
    }
    return Promise.resolve();
}

/**
 * Transforms the chart's nodes to the backend structure
 * @param nodes - Nodes from the chart
 * @return {object} Filtered nodes by type modelled according to backend
 */
export function transformChartNodesToBackendStructure(nodes: StatusNode[]): TransformedNodes {
    const projectLabelNodes = nodes
        .filter((node) => node.nodeType === WorkflowNodeType.PROJECT_LABEL_NODE && node.status != null)
        .map((node) => {
            return {
                id: node.id,
                x: node.x,
                y: node.y,
                projectLabelId: (node.status as Label).id,
            };
        });

    const projectLabelTemplateNodes = nodes
        .filter((node) => node.nodeType === WorkflowNodeType.PROJECT_LABEL_TEMPLATE_NODE && node.status != null)
        .map((node) => {
            return {
                id: node.id,
                x: node.x,
                y: node.y,
                projectLabelTemplateId: (node.status as ProjectLabelTemplate).id,
            };
        });

    const systemNodes = nodes
        .filter((node) => node.nodeType === WorkflowNodeType.SYSTEM_NODE)
        .map((node) => {
            return {
                id: node.id,
                x: node.x,
                y: node.y,
                type: node.status && node.status.id === GlobalOptions.ALL ? 'all' : node.type,
                name: node.status && node.status.id === GlobalOptions.ALL ? 'All' : capitalizeWord(node.type, true),
            };
        });

    return {
        projectLabelNodes,
        systemNodes,
        projectLabelTemplateNodes,
    };
}

/**
 * Transforms the chart's connections to the backend structure
 * @param connections - Connections from the chart
 * @return {object} Return the transformed connections as an array in an object
 */
export function transformChartConnectionsToBackendStructure(connections: Connection[]): TransformedConnections {
    const transformedConnections = connections.map((connection) => {
        return {
            id: connection.id,
            sourcePosition: connection.source.position,
            destinationPosition: connection.destination.position,
            sourceNode: connection.source.id,
            destinationNode: connection.destination.id,
        };
    });

    return {
        connections: transformedConnections,
    };
}

/**
 * Transforms the nodes fetched from backend to chart's format
 * @param workflow - The reference to the workflow retrieved from the ORM
 * @param isOrderStatusActive - Used to highlight the roltek production node
 * @param activeStatusId - Used to highlight the correct node based on the status id
 * @return {array} Returns an array of nodes formatted for the chart library
 */
export function transformBackendNodesToChartNodesFromWorkflow(
    workflow: Workflow | WorkflowTemplate,
    isOrderStatusActive?: boolean,
    activeStatusId?: string | null
): StatusNode[] {
    const otherNodes = convertProjectLabelAndProjectLabelTemplateNodesToStatusNodes(workflow, activeStatusId);
    const systemNodes = convertSystemNodesToStatusNodes(workflow, isOrderStatusActive, activeStatusId);

    return [...otherNodes, ...systemNodes];
}

/**
 * Converts project labels or project label templates to status nodes
 * @param workflow - The reference to the workflow retrieved from the ORM
 * @param activeStatusId - Used to highlight the correct node based on the status id
 * @return {array} Returns an array of status nodes based on the instance of workflow
 */
export function convertProjectLabelAndProjectLabelTemplateNodesToStatusNodes(
    workflow: Workflow | WorkflowTemplate,
    activeStatusId?: string | null
) {
    if (workflow instanceof WorkflowTemplate) {
        const projectLabelTemplateNodes = workflow.projectLabelTemplateNodes.map((projectLabelTemplateNode) => {
            return convertProjectLabelTemplateNodeToStatusNode(projectLabelTemplateNode);
        });
        return projectLabelTemplateNodes;
    } else {
        const projectLabelNodes = workflow.projectLabelNodes.map((projectLabelNode) => {
            return convertProjectLabelNodeToStatusNode(projectLabelNode, activeStatusId);
        });
        return projectLabelNodes;
    }
}

/**
 * Converts the workflow's system nodes to status nodes
 * @param workflow - The reference to the workflow retrieved from the ORM
 * @param isOrderStatusActive - Used to highlight the roltek production node
 * @param activeStatusId - Used to highlight the correct node based on the status id
 * @return {array} Returns an array of status nodes
 */
export function convertSystemNodesToStatusNodes(
    workflow: WorkflowTemplate | Workflow,
    isOrderStatusActive = false,
    activeStatusId?: string | null
) {
    return workflow.systemNodes.map((systemNode) => {
        const allNode = convertAllNodeToStatusNode(systemNode, workflow, activeStatusId);

        if (allNode != null) {
            return allNode;
        }

        return {
            id: systemNode.id,
            x: systemNode.x,
            y: systemNode.y,
            type: systemNode.type,
            nodeType: WorkflowNodeType.SYSTEM_NODE,
            status: null,
            isActive: systemNode.type === SystemNodeType.ROLTEK_PRODUCTION && isOrderStatusActive,
            approvers:
                systemNode.type === SystemNodeType.ROLTEK_PRODUCTION
                    ? [{ name: i18n.t('Roltek produkcija') as string }]
                    : undefined,
            width: systemNode.type === SystemNodeType.ROLTEK_PRODUCTION ? 150 : undefined,
        };
    });
}

/**
 * Converts a system node to an all status node, if it really is an all node
 * @param systemNode - The node that should be tested and converted if necessary
 * @param workflow - The reference to the workflow retrieved from the ORM
 * @param activeStatusId - Used to highlight the correct node based on the status id
 * @return A status node if it's an all node, null if it's not
 */
export function convertAllNodeToStatusNode(
    systemNode: SystemWorkflowNode,
    workflow: Workflow | WorkflowTemplate,
    activeStatusId?: string | null
) {
    const allLabel =
        workflow instanceof Workflow
            ? LabelReporistory.getById(GlobalOptions.ALL)
            : ProjectLabelTemplateRepository.getById(GlobalOptions.ALL);

    if (allLabel && systemNode.type === GlobalOptions.ALL) {
        return convertProjectLabelNodeToStatusNode(
            ({
                ...systemNode,
                projectLabel: allLabel,
            } as unknown) as ProjectLabelWorkflowNode,
            activeStatusId
        );
    }

    return null;
}

/**
 * Converts a project label node to the status node supported by chart
 * @param backendNode - The project label node that needs to be converted
 * @param activeStatusId - Used to highlight the correct node based on the status id
 * @return Returns the converted node
 */
export function convertProjectLabelNodeToStatusNode(
    backendNode: ProjectLabelWorkflowNode,
    activeStatusId?: string | null
) {
    const suggestedWidth = generateAppropriateNodeWidth(backendNode.projectLabel.name);

    return {
        id: backendNode.id,
        x: backendNode.x,
        y: backendNode.y,
        type: 'operation',
        name: 'Status',
        isActive: activeStatusId === backendNode.projectLabel.id,
        nodeType:
            backendNode.projectLabel.id === GlobalOptions.ALL
                ? WorkflowNodeType.SYSTEM_NODE
                : WorkflowNodeType.PROJECT_LABEL_NODE,
        status: backendNode.projectLabel,
        width: suggestedWidth,
        approvers: [{ name: backendNode.projectLabel.name }],
    };
}

/**
 * Converts a project label template node to the status node supported by chart
 * @param backendNode - The project label template node that needs to be converted
 * @return Returns the converted node
 */
export function convertProjectLabelTemplateNodeToStatusNode(backendNode: ProjectLabelTemplateWorkflowNode) {
    const suggestedWidth = generateAppropriateNodeWidth(backendNode.projectLabelTemplate.name);

    return {
        id: backendNode.id,
        x: backendNode.x,
        y: backendNode.y,
        type: 'operation',
        name: 'Status',
        isActive: false,
        nodeType: WorkflowNodeType.PROJECT_LABEL_TEMPLATE_NODE,
        status: backendNode.projectLabelTemplate,
        width: suggestedWidth,
        approvers: [{ name: backendNode.projectLabelTemplate.name }],
    };
}

/**
 * Transforms the connections fetched from backend to chart's format
 * @return {array} Returns an array of connections formatted for the chart library
 */
export function transformBackendConnectionsToChartConnectionsFromWorkflow(
    workflow: Workflow | WorkflowTemplate
): Connection[] {
    return workflow.connections.map((connection) => {
        return {
            id: connection.id,
            source: {
                id: connection.sourceNode,
                position: connection.sourcePosition,
            },
            destination: {
                id: connection.destinationNode,
                position: connection.destinationPosition,
            },
            type: 'pass',
        };
    });
}

/**
 * Tries to retrieve the initial nodes from the first workflow in the ORM If nothing exists returns the default setup
 * @param isWorkflowTemplate - Decides where the workflow will be fetched from
 * @param isOrderStatusActive - Highlight the roltek production node if set to true
 * @param activeStatusId - The id the of the active status, so it can be highlighted
 * @return {array} Returns an array of either existing StatusNodes or the default ones
 */
export function retrieveNodes(
    isWorkflowTemplate: boolean,
    isOrderStatusActive?: boolean,
    activeStatusId?: string | null
): StatusNode[] {
    const workflow = isWorkflowTemplate ? WorkflowTemplateRepository.getFirst() : WorkflowRepository.getFirst();

    if (workflow == null) {
        return generateDefaultNodes();
    }

    return transformBackendNodesToChartNodesFromWorkflow(workflow, isOrderStatusActive, activeStatusId);
}

/**
 * Tries to retrieve the connections from the first workflow in the ORM. If nothing exists, returns an empty array
 * @param isWorkflowTemplate - Decides where the workflow will be fetched from
 * @return {array} Returns an array of either existing Connections or an empty array
 */
export function retrieveConnections(isWorkflowTemplate: boolean): Connection[] {
    const workflow = isWorkflowTemplate ? WorkflowTemplateRepository.getFirst() : WorkflowRepository.getFirst();

    if (workflow == null) {
        return [];
    }

    return transformBackendConnectionsToChartConnectionsFromWorkflow(workflow);
}

/**
 * Handles the behaviour when the user confirms creating/editing a new/existing node
 * @param {WorkflowModalSettings} workflowModalSettings - represents the used service,
 * selected label, custom status and target node
 */
export function handleModalConfirm(workflowModalSettings: WorkflowModalSettings) {
    try {
        if (workflowModalSettings.customStatus != null) {
            verifyCustomStatus(workflowModalSettings.customStatus as string);
        } else {
            verifySelectedStatus(workflowModalSettings.selectedStatusId, workflowModalSettings.isWorkflowTemplate);
        }
    } catch (e) {
        throw e;
    }

    const entityRepositoryAction = workflowModalSettings.isWorkflowTemplate
        ? ProjectLabelTemplateRepository.getById
        : LabelReporistory.getById;
    const selectedStatus = entityRepositoryAction(workflowModalSettings.selectedStatusId as string) as
        | Label
        | ProjectLabelTemplate;

    if (workflowModalSettings.target == null) {
        workflowModalSettings.workflowService.createNewNode(workflowModalSettings.customStatus, selectedStatus);
    } else {
        workflowModalSettings.workflowService.updateExistingNode(
            workflowModalSettings.customStatus,
            selectedStatus,
            workflowModalSettings.target
        );
    }
}

/**
 * Checks if a label is selected
 * @param selectedStatusId - Id of the selected label
 * @param isWorkflowTemplate - Decides which entity will be retrieved
 * @return Returns true if validation is successful, false if not
 */
function verifySelectedStatus(selectedStatusId: string | null, isWorkflowTemplate: boolean) {
    if (selectedStatusId === '' || selectedStatusId == null) {
        throw new Error(i18n.t('Morate odabrati status') as string);
    }

    const entityRepositoryAction = isWorkflowTemplate
        ? ProjectLabelTemplateRepository.getById
        : LabelReporistory.getById;
    const selectedStatus = entityRepositoryAction(selectedStatusId);

    if (selectedStatus == null) {
        throw new Error(i18n.t('Odabrani status ne postoji') as string);
    }

    return true;
}

/**
 * Returns an error if the custom status is empty
 * @param customStatus - The name of the custom status
 */
function verifyCustomStatus(customStatus: string) {
    if (customStatus === '') {
        throw new Error(i18n.t('Morate upisati status') as string);
    }

    return true;
}

/**
 * Sends an API request for creating or updating a workflow
 * @param nodes - Current nodes in the chart
 * @param connections - Current connections in the chart
 * @param deletedNodesIds - An array of strings containing id's of nodes that will be deleted
 * @param deletedConnectionsIds - An array of strings containing id's of connections that will be deleted
 * @param localeEntity - Locale entity which is being updated (if it exists)
 * @return {promise} - Returns an empty promise if everything was okay
 */
export async function createOrUpdateWorkflow(
    nodes: StatusNode[],
    connections: Connection[],
    deletedNodesIds: string[],
    deletedConnectionsIds: string[],
    localeEntity: Locale | null
) {
    const currentWorkflow =
        localeEntity == null ? WorkflowRepository.getFirst() : WorkflowTemplateRepository.getFirst();
    const requestResource: WorkflowRequestResource = generateWorkflowRequestResource(
        nodes,
        connections,
        deletedNodesIds,
        deletedConnectionsIds,
        localeEntity
    );

    try {
        if (currentWorkflow == null) {
            const appropriateWorkflowAction =
                localeEntity == null
                    ? Workflow.createNew.bind(Workflow)
                    : WorkflowTemplate.createNew.bind(WorkflowTemplate);
            await appropriateWorkflowAction(requestResource);
        } else {
            const appropriateWorkflowAction =
                localeEntity == null
                    ? Workflow.updateExisting.bind(Workflow)
                    : WorkflowTemplate.updateExisting.bind(WorkflowTemplate);
            requestResource.workflowId = currentWorkflow.id;
            await appropriateWorkflowAction(requestResource);
        }
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Transforms all the nodes, connections and nodes to be deleted in a backend-accepted request format
 * @param nodes - Current nodes in the chart
 * @param connections - Current connections in the chart
 * @param deletedNodesIds - An array of strings containing id's of nodes that will be deleted
 * @param deletedConnectionsIds - An array of strings containing id's of connections that will be deleted
 * @param localeEntity - Locale entity which is being updated (if it exists)
 * @return {object} - Returns an object containing the transformed data
 */
export function generateWorkflowRequestResource(
    nodes: StatusNode[],
    connections: Connection[],
    deletedNodesIds: string[],
    deletedConnectionsIds: string[],
    localeEntity: Locale | null
) {
    const transformedNodes = transformChartNodesToBackendStructure(nodes);
    const transformedConnections = transformChartConnectionsToBackendStructure(connections);
    const transformedDeletedNodes = deletedNodesIds.map((id) => {
        return {
            id,
        };
    });
    const transformedConnectionsIds = deletedConnectionsIds.map((id) => {
        return {
            id,
        };
    });

    return {
        workflowPayload: {
            ...transformedConnections,
            ...transformedNodes,
            deletedNodes: transformedDeletedNodes,
            deletedConnections: transformedConnectionsIds,
            locale: localeEntity,
        },
    };
}

/**
 * Notifies the user about any backend errors that might have occurred
 * @param e - The error that occurred
 * @param errors - The errors array from JSON API
 */
export function handleBackendErrors(e: Error, errors: JsonAPIError[]) {
    if (errors.length > 0) {
        errors.forEach((error) => {
            let errorDescription = error.detail;
            switch (error.detail) {
                case 'Start node must have exactly one source connection':
                    errorDescription = i18n.t('Početak mora voditi u točno jedan status') as string;
            }

            notification.error({
                message: i18n.t('Dogodila se greška') as string,
                description: errorDescription,
            });
        });
    } else {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: (e as Error).message,
        });
    }
}

/**
 * Used by the custom render function
 */
function roundTo20(targetNumber: number) {
    return targetNumber < 20 ? 20 : targetNumber;
}

/**
 * Adjusted render function
 * The bulk of it was copied from
 * https://github.com/joyceworks/flowchart-vue/blob/master/src/components/flowchart/Flowchart.vue
 */
// @ts-ignore
export function renderFlowchart(g, node, isSelected) {
    node.width = node.width || 120;
    node.height = node.height || 60;
    let borderColor = isSelected ? '#666666' : '#bbbbbb';
    borderColor = node.isActive ? '#007aff' : borderColor;

    if (node.type !== 'start' && node.type !== 'end') {
        // title
        g.append('rect')
            .attr('x', node.x)
            .attr('y', node.y)
            .attr('stroke', borderColor)
            .attr('class', 'title')
            .style('height', '20px')
            .style('fill', '#f1f3f4')
            .style('stroke-width', '1px')
            .style('width', node.width + 'px');
        // tslint:disable-next-line:max-line-length
        g.append('text')
            .attr('x', node.x + 4)
            .attr('y', node.y + 15)
            .attr('class', 'unselectable')
            .text(() => node.name)
            .each(function wrap() {
                // @ts-ignore
                // tslint:disable-next-line:prefer-const one-variable-per-declaration
                let self = d3.select(this),
                    textLength = self.node().getComputedTextLength(),
                    // tslint:disable-next-line:no-shadowed-variable
                    text = self.text();
                while (textLength > node.width - 2 * 4 && text.length > 0) {
                    text = text.slice(0, -1);
                    self.text(text + '...');
                    textLength = self.node().getComputedTextLength();
                }
            });
    }
    // body
    const body = g.append('rect').attr('class', 'body');
    body.style('width', node.width + 'px')
        .style('fill', 'white')
        .style('stroke-width', '1px');
    if (node.type !== 'start' && node.type !== 'end') {
        body.attr('x', node.x).attr('y', node.y + 20);
        body.style('height', roundTo20(node.height - 20) + 'px');
    } else {
        body.attr('x', node.x)
            .attr('y', node.y)
            .classed(node.type, true)
            .attr('rx', 30);
        body.style('height', roundTo20(node.height) + 'px');
    }
    body.attr('stroke', borderColor);

    // body text
    const text =
        node.type === 'start'
            ? i18n.t('Početak')
            : node.type === 'end'
            ? i18n.t('Kraj')
            : !node.approvers || node.approvers.length === 0
            ? 'No approver'
            : node.approvers.length > 1
            ? `${node.approvers[0].name + '...'}`
            : node.approvers[0].name;
    let bodyTextY;
    if (node.type !== 'start' && node.type !== 'end') {
        bodyTextY = node.y + 25 + roundTo20(node.height - 20) / 2;
    } else {
        bodyTextY = node.y + 5 + roundTo20(node.height) / 2;
    }
    // tslint:disable-next-line:only-arrow-functions
    g.append('text')
        .attr('x', node.x + node.width / 2)
        .attr('y', bodyTextY)
        .attr('class', 'unselectable')
        .attr('text-anchor', 'middle')
        .text(function() {
            return text;
        })
        .each(function wrap() {
            // @ts-ignore
            // tslint:disable-next-line:prefer-const one-variable-per-declaration
            let self = d3.select(this),
                textLength = self.node().getComputedTextLength(),
                // tslint:disable-next-line:no-shadowed-variable
                text = self.text();
            while (textLength > node.width - 2 * 4 && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + '...');
                textLength = self.node().getComputedTextLength();
            }
        });
}
