// @ts-ignore
import FlowChart from 'flowchart-vue';
import Label from '@/models/Label';
import { generateAppropriateNodeWidth } from '@/helpers/Workflow/WorkflowHelper';
import { StatusNode } from '@/interfaces/components/Workflow/StatusNode';
import i18n from '@/i18n';
import { Connection } from '@/interfaces/components/Workflow/Connection';
import { WorkflowNodeType } from '@/enums/components/Workflow/WorkflowNodeType';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { notification } from 'ant-design-vue';
import { SystemNodeType } from '@/enums/components/Workflow/SystemNodeType';

export class WorkflowService {
    private chartReference: FlowChart;
    private temporaryIdCounter = 2;

    constructor(chartReference: FlowChart | null) {
        if (chartReference == null) {
            throw new Error('The reference to chart element not found');
        }
        this.chartReference = chartReference;
    }

    /**
     * Adds a new node to the chart from the given status
     * @param customStatus - custom status name
     * @param selectedStatus - label or project label template object that was selected
     */
    public createNewNode(customStatus: string | null, selectedStatus: Label | ProjectLabelTemplate | null) {
        if (customStatus == null && selectedStatus == null) {
            throw new Error('No custom status or label were provided when creating a new node');
        }
        const suggestedWidth = generateAppropriateNodeWidth(
            (selectedStatus != null ? selectedStatus.name : customStatus) as string
        );
        const approvers = [{ name: selectedStatus != null ? selectedStatus.name : customStatus }];
        let nodeType = WorkflowNodeType.PROJECT_LABEL_NODE;

        if (selectedStatus instanceof ProjectLabelTemplate) {
            nodeType = WorkflowNodeType.PROJECT_LABEL_TEMPLATE_NODE;
        }

        if (selectedStatus && selectedStatus.id === GlobalOptions.ALL) {
            nodeType = WorkflowNodeType.SYSTEM_NODE;
        }

        this.chartReference.add({
            id: `new-${this.temporaryIdCounter}`,
            x: 10,
            y: 10,
            type: 'operation',
            status: selectedStatus,
            name: 'Status',
            nodeType,
            width: suggestedWidth,
            approvers,
        });

        this.temporaryIdCounter++;
    }

    /**
     * Updates the status, width and approvers of node based on selected status
     * @param customStatus - custom status name
     * @param selectedStatus - label or project label template object that was selected
     * @param target - object that needs to be updated
     */
    public updateExistingNode(
        customStatus: string | null,
        selectedStatus: Label | ProjectLabelTemplate | null,
        target: StatusNode
    ) {
        if (customStatus == null && selectedStatus == null) {
            throw new Error('No custom status or label were provided when creating a new node');
        }
        const suggestedWidth = generateAppropriateNodeWidth(
            (selectedStatus != null ? selectedStatus.name : customStatus) as string
        );
        const approvers = [{ name: (selectedStatus != null ? selectedStatus.name : customStatus) as string }];

        target.status = selectedStatus;
        target.width = suggestedWidth;
        target.approvers = approvers;
    }

    /**
     * Handles deleting nodes and connections, but prevents deleting initial nodes
     * @param nodes - Optional param, deletes the given nodes if present
     */
    public deleteNodes(nodes?: StatusNode[]) {
        if (this.chartReference.currentNodes.length <= 0) {
            this.chartReference.remove();

            return;
        }

        const selectedNodes = this.chartReference.currentNodes;
        const nodesToBeDeleted: StatusNode[] = nodes || selectedNodes;

        for (const selectedNode of nodesToBeDeleted) {
            if (
                selectedNode.type === SystemNodeType.START ||
                selectedNode.type === SystemNodeType.END ||
                selectedNode.type === SystemNodeType.ROLTEK_PRODUCTION
            ) {
                throw new Error(i18n.t('Nije moguće obrisati početak, kraj ili Roltek status') as string);
            }

            this.chartReference.removeNode(selectedNode);
        }
    }

    /**
     * Handles given connections
     * @param connections - The connections that need to be deleted
     */
    public deleteConnections(connections: Connection[]) {
        if (connections.length <= 0) {
            return;
        }

        for (const connection of connections) {
            this.chartReference.removeConnection(connection);
        }
    }

    /**
     * Handles deleting nodes and connections with the keyboard and emits events if the data changed
     * @param event - The keyboard event
     * @param selectedNodes - The nodes that are currently selected in the chart
     * @param selectedConnections - The connections that are currently selected in the chart
     */
    public keyboardDelete(event: KeyboardEvent, selectedNodes: StatusNode[], selectedConnections: Connection[]) {
        if (event.key === 'Backspace') {
            try {
                this.deleteNodes(selectedNodes);
                this.deleteConnections(selectedConnections);
            } catch (e) {
                notification.error({
                    message: i18n.t('Dogodila se greška') as string,
                    description: (e as Error).message,
                });
            }

            EventBus.$emit(EventBusEvents.changesInDataMade, {
                state: true,
                component: 'workflow',
            });
        }
    }

    /**
     * Checks if the destination of the connection is roltekProduction and deletes it
     * @param connection - newly created connection
     * @param roltekProductionNode - The roltek production StatusNode so its ID can be used
     */
    public removeConnectionIfDestinationIsRoltekProduction(connection: Connection, roltekProductionNode: StatusNode) {
        if (connection.destination.id === roltekProductionNode.id) {
            this.chartReference.removeConnection(connection);
        }
    }
}
