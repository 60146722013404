
import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectStatusModal from '@/components/views/Workflow/SelectStatusModal.vue';
// @ts-ignore
import FlowChart from 'flowchart-vue';
import { WorkflowService } from '@/services/WorkflowService';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'WorkflowActions',
    components: { SelectStatusModal },
})
export default class WorkflowActions extends Vue {
    @Prop({ required: true }) private chartReference!: FlowChart;
    @Prop({ required: true }) private showModal!: () => void;
    @Prop({ required: true }) private resetWorkflow!: () => void;
    @Prop({ required: true }) private workflowService!: WorkflowService;

    private get isTesting() {
        // checks if Cypress is currently running the app
        // @ts-ignore
        return window.Cypress;
    }

    private handleAddingNewNode() {
        this.showModal();
    }

    private handleDeletingNodes() {
        try {
            this.workflowService.deleteNodes();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        }

        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state: true,
            component: 'workflow',
        });
    }
}
